import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';
import PrintPreviewAction from '../../Common/PrintPreview/actions';

import { Features } from '../../../Constants';

const Detail = lazy(() => import('./Detail'));

export default function SettingWorker(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingWorkerReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingWorkerReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const workers = useSelector(
        state => state.SettingWorkerReducer.get('workers'),
        (a, b) => is(a, b)
    );

    const worker = useSelector(
        state => state.SettingWorkerReducer.get('worker'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingWorkerReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingWorkerReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Worker'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, selected }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                return dispatch(PrintPreviewAction.show({ section: 'schools', selected }));
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value, checked } = values;

        if (name) {
            dispatch(Actions.show(worker.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(worker.merge(fromJS(values))));
        }
    };

    const DetailRef = useRef();

    const features = Features.filter(({ admin }) => admin);

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    loading={loading_index}
                    columns={columns}
                    rows={workers}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                />
                <Suspense>
                    <Detail
                        features={features}
                        ref={DetailRef}
                        loading={loading_show}
                        worker={worker}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingWorkerReducer } from './reducers';
export { default as SettingWorkerAction } from './actions';
