import { Button, Dropdown, Form } from 'semantic-ui-react';
import React from 'react';
import { is, Map } from 'immutable';
import { useSelector } from 'react-redux';
import { DatePicker } from '../../../Common';

export default function ScholarSearch(props) {
    const { selected = [], onAction = () => {}, onSearch = () => {}, search = Map() } = props;

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const customer = search.get('customer') ?? '';
    const start_date = search.get('start_date') ?? '';
    const end_date = search.get('end_date') ?? '';
    const date_mode = search.get('date_mode') ?? '';

    return (
        <Form size="tiny" widths="equal">
            <Form.Group>
                <Form.Field width={11}>
                    <Button
                        icon="refresh"
                        color="teal"
                        type="button"
                        size="tiny"
                        content="刷新"
                        action="refresh"
                        onClick={onAction}
                    />
                    <Dropdown
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        icon={null}
                        value=""
                        disabled={!selected.length}
                        trigger={<Button size="tiny" color="red" content="删除" icon="trash" />}
                        options={[{ value: 'remove', text: '你确定要删除吗？' }]}
                        action="destroy"
                        onChange={onAction}
                    />
                    <Dropdown
                        icon={null}
                        value=""
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        disabled={!selected.length}
                        trigger={<Button content="例外" color="purple" icon="exclamation" size="tiny" />}
                        action="exclude"
                        options={[{ value: 'delete', text: '你确定要设置成例外吗？' }]}
                        onChange={onAction}
                    />
                    <Dropdown
                        icon={null}
                        value=""
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        disabled={!selected.length}
                        trigger={<Button content="科目" color="teal" icon="table" size="tiny" />}
                        action="project"
                        options={[
                            { value: 2, text: '科二' },
                            { value: 3, text: '科三' },
                            { value: 5, text: '科二，科三' },
                        ]}
                        onChange={onAction}
                    />
                </Form.Field>
                <DatePicker
                    width={2}
                    mode="search"
                    showMonthYearPicker
                    placeholder="注册日期"
                    search_mode={date_mode}
                    start_date={start_date}
                    end_date={end_date}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={2}
                    clearable
                    search
                    placeholder="客户"
                    options={customers_options}
                    name="customer"
                    customer={customer}
                    onChange={onSearch}
                />
            </Form.Group>
        </Form>
    );
}
