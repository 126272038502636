import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is, List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';
import PrintPreviewAction from '../../Common/PrintPreview/actions';

import ResourceAction from '../Resource/actions';
import Search from './Search';

const Detail = lazy(() => import('./Detail'));
const ResourceDetail = lazy(() => import('../Resource/Detail'));

export default function SettingCustomer(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingCustomerReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingCustomerReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const loading_resource = useSelector(
        state => state.SettingCustomerReducer.get('loading_resource'),
        (a, b) => is(a, b)
    );

    const customers = useSelector(
        state => state.SettingCustomerReducer.get('customers'),
        (a, b) => is(a, b)
    );

    const customer = useSelector(
        state => state.SettingCustomerReducer.get('customer'),
        (a, b) => is(a, b)
    );

    const resource = useSelector(
        state => state.SettingCustomerReducer.get('resource'),
        (a, b) => is(a, b)
    );

    const courses = useSelector(
        state => state.SettingCustomerReducer.get('courses'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingCustomerReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingCustomerReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const groups = useSelector(
        state => state.SettingResourceReducer.get('groups'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Customer'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(Actions.course());
        dispatch(ResourceAction.group_requests());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, selected }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                return dispatch(PrintPreviewAction.show({ section: 'schools', selected }));
            case 'add_resource':
                dispatch(Actions.resource(fromJS({}).set('customers_id', customer.get('id'))));
                return ResourceDetailRef.current.handleOpen();
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value, checked, updated } = values;

        if (updated) {
            dispatch(Actions.show(updated));
        } else if (name === 'courses' || name === 'demo' || name === 'features') {
            if (name === 'features' && value === 'scan' && !checked) {
                dispatch(Actions.show(customer.set(name, List())));
            } else {
                dispatch(
                    Actions.show(
                        customer.update(name, values => {
                            return checked
                                ? (values ?? List()).push(value)
                                : (values ?? List()).filter(c => c !== value);
                        })
                    )
                );
            }
        } else if (name === 'project2' || name === 'project3') {
            dispatch(
                Actions.show(customer.update('project', project => (project ?? 0) + (checked ? value : value * -1)))
            );
        } else {
            if (name) {
                dispatch(Actions.show(customer.set(name, fromJS(value))));
            } else {
                dispatch(Actions.show(customer.merge(fromJS(values))));
            }
        }
    };

    const handleResourceSelect = row => {
        dispatch(Actions.resource_request(row.id));
        return ResourceDetailRef?.current?.handleOpen();
    };

    const handleResourceAction = (evt, { action, selected }) => {
        switch (action) {
            case 'update':
                return dispatch(Actions.resource_create());
            case 'destroy':
                return dispatch(Actions.resource_destroy());
            case 'remove':
                return dispatch(Actions.resource_remove({ selected }));
            default:
        }
    };

    const handleResourceChange = (evt, values) => {
        const { name, value } = values;

        if (name) {
            dispatch(Actions.resource(resource.set(name, fromJS(value))));
        } else {
            dispatch(Actions.resource(resource.merge(fromJS(values))));
        }
    };

    const handleResourceUpload = formData => {
        dispatch(Actions.resource_upload(formData));
    };

    const DetailRef = useRef();
    const ResourceDetailRef = useRef();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <Search search={search} onSearch={handleSearch} onAction={handleAction} />
                <DataGrid
                    refresh={false}
                    addition={false}
                    loading={loading_index}
                    columns={columns}
                    rows={customers}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                />
                <Suspense>
                    <Detail
                        ref={DetailRef}
                        ResourceDetailRef={ResourceDetailRef}
                        loading={loading_show}
                        customer={customer}
                        courses={courses}
                        onAction={handleAction}
                        onChange={handleChange}
                        onResourceSelect={handleResourceSelect}
                    />
                    <ResourceDetail
                        loading={loading_resource}
                        ref={ResourceDetailRef}
                        groups={groups}
                        resource={resource}
                        onAction={handleResourceAction}
                        onChange={handleResourceChange}
                        onUpload={handleResourceUpload}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingCustomerReducer } from './reducers';
export { default as SettingCustomerAction } from './actions';
