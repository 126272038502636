import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    loading_resource: false,
    search: {
        disabled: false,
    },
    customers: [],
    customer: {},
    resource: {},
    options: [],
    courses: [],
    columns: [
        { key: 'name', name: '名称', width: 200, frozen: true },
        { key: 'abbr', name: '简称', width: 100, frozen: true },
        { key: 'mobile', name: '手机', width: 120, frozen: true },
        { key: 'phone', name: '电话', width: 120, frozen: true },
        { key: 'contact', name: '连络人', width: 100, frozen: true },
        {
            key: 'features',
            name: '功能',
            minWidth: 300,
            formatter: ({ row, column }) => {
                let values = row[column.key] ?? [];
                return values
                    .map(value => {
                        switch (value) {
                            case 'scan':
                                return '扫码登录';
                            case 'booking':
                                return '预约学习';
                            case 'clearance':
                                return '学习通关';
                            case 'record':
                                return '学习记录';
                            default:
                                return '';
                        }
                    })
                    .join(', ');
            },
        },
        { key: 'project', name: '科目', width: 100 },
        { key: 'price2', name: '科二价格', width: 80, formatter: NumericFormatter },
        { key: 'price3', name: '科三价格', width: 80, formatter: NumericFormatter },
        { key: 'resources_count', name: '组件', width: 70, formatter: NumericFormatter },
        { key: 'schools_count', name: '驾校', width: 70, formatter: NumericFormatter },
        { key: 'simulators_count', name: '模拟器', width: 70, formatter: NumericFormatter },
        { key: 'students_count', name: '学员数', width: 70, formatter: NumericFormatter },
        { key: 'pay_day', name: '收款日', width: 80, formatter: NumericFormatter },
        { key: 'expire', name: '停机日', width: 80, formatter: NumericFormatter },
        { key: 'account', name: '收款账户', width: 80 },
        { key: 'address', name: '地址', width: 300 },
    ],
});
export default function SettingCustomerReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_CUSTOMER_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_CUSTOMER_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_CUSTOMER_LOADING_RESOURCE:
            return action.toggle
                ? state.set('loading_resource', action.toggle)
                : state.update('loading_resource', loading => !loading);

        case ACTION_TYPES.SETTING_CUSTOMER_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_CUSTOMER_INDEX:
            return state.set('customers', fromJS(action.customers)).set('loading_index', false);

        case ACTION_TYPES.SETTING_CUSTOMER_SHOW:
            return state
                .set('customer', initialState.get('customer').merge(fromJS(action.customer)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_CUSTOMER_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_CUSTOMER_COURSE:
            return state.set('courses', fromJS(action.courses));

        case ACTION_TYPES.SETTING_CUSTOMER_RESOURCE:
            return state.set('resource', fromJS(action.resource)).set('loading_resource', false);

        case ACTION_TYPES.SETTING_CUSTOMER_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
