import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Button, Form, Icon, Popup, Sidebar } from 'semantic-ui-react';

import { DataGrid, Toast } from '../../Common';

import Actions from './actions';
import SettingCustomerAction from '../Customer/actions';

const Detail = lazy(() => import('./Detail'));
const Group = lazy(() => import('./Group'));

export default function SettingResource(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingResourceReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingResourceReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const resources = useSelector(
        state => state.SettingResourceReducer.get('resources'),
        (a, b) => is(a, b)
    );

    const resource = useSelector(
        state => state.SettingResourceReducer.get('resource'),
        (a, b) => is(a, b)
    );

    const groups = useSelector(
        state => state.SettingResourceReducer.get('groups'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingResourceReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingResourceReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Resource'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(Actions.requests());
        dispatch(Actions.group_requests());
        dispatch(SettingCustomerAction.options());
    }, []);

    const handleAction = (evt, { action, selected }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'remove':
                return dispatch(Actions.remove({ selected }));
            case 'connect':
                break;
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value } = values;

        if (name) {
            dispatch(Actions.show(resource.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(resource.merge(fromJS(values))));
        }
    };

    const handleCopy = text => {
        navigator.clipboard.writeText(text);
        Toast.success('文字已复制');
    };

    const handleUpload = formData => {
        dispatch(Actions.upload(formData));
    };

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const DetailRef = useRef();
    const GroupRef = useRef();

    const customer = search.get('customer');

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    loading={loading_index}
                    columns={columns}
                    rows={resources}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                    rightInput={
                        <React.Fragment>
                            <Select
                                clearable
                                search
                                placeholder="客户"
                                options={customers_options.concat([{ value: 'common', text: '共用' }])}
                                name="customer"
                                value={customer}
                                onChange={handleSearch}
                            />
                        </React.Fragment>
                    }
                    customInput={
                        <React.Fragment>
                            <Button
                                color="purple"
                                icon="folder open"
                                size="tiny"
                                content="分类"
                                onClick={() => GroupRef?.current?.handleOpen()}
                            />
                            <Popup
                                on="click"
                                trigger={<Button color="olive" icon="cloud" size="tiny" content="连线资料" />}
                                content={
                                    <Form widths="equal" style={{ minWidth: 800 }}>
                                        <Form.Group>
                                            <Form.Input
                                                label={
                                                    <label>
                                                        连线路径
                                                        <Icon
                                                            name="copy outline"
                                                            link
                                                            onClick={() =>
                                                                handleCopy(
                                                                    `${window.location.origin}/api/setting/resource/upgrade`
                                                                )
                                                            }
                                                        />
                                                    </label>
                                                }
                                                value={`${window.location.origin}/api/setting/resource/upgrade`}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.TextArea
                                                label={
                                                    <label>
                                                        令牌
                                                        <Icon
                                                            name="copy outline"
                                                            link
                                                            onClick={() => handleCopy(sessionStorage.getItem('token'))}
                                                        />
                                                    </label>
                                                }
                                                value={sessionStorage.getItem('token')}
                                            />
                                        </Form.Group>
                                    </Form>
                                }
                            />
                            <div style={{ float: 'right' }}>
                                {/*<Select*/}
                                {/*    fluid*/}
                                {/*    width={2}*/}
                                {/*    clearable*/}
                                {/*    search*/}
                                {/*    placeholder="客户"*/}
                                {/*    options={customers_options}*/}
                                {/*    name="customer"*/}
                                {/*    customer={customer}*/}
                                {/*    onChange={handleSearch}*/}
                                {/*/>*/}
                            </div>
                        </React.Fragment>
                    }
                />
                <Suspense>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        resource={resource}
                        groups={groups}
                        customers_options={customers_options}
                        onAction={handleAction}
                        onChange={handleChange}
                        onUpload={handleUpload}
                    />
                    <Group ref={GroupRef} groups={groups} loading={loading_show} />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingResourceReducer } from './reducers';
export { default as SettingResourceAction } from './actions';
