import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const Api = lazy(() => import('./Api'));
const Campus = lazy(() => import('./Campus'));
const Course = lazy(() => import('./Course'));
const Customer = lazy(() => import('./Customer'));
const Finance = lazy(() => import('./Finance'));
const Resource = lazy(() => import('./Resource'));
const Revenue = lazy(() => import('./Revenue'));
const Scholar = lazy(() => import('./Scholar'));
const Setting = lazy(() => import('./Setting'));
const Simulator = lazy(() => import('./Simulator'));
const Summary = lazy(() => import('./Summary'));
const Token = lazy(() => import('./Token'));
const Version = lazy(() => import('./Version'));
const Worker = lazy(() => import('./Worker'));

export default function Attendance(props) {
    return (
        <Suspense>
            <Routes>
                <Route path="/Api" element={<Api />} />
                <Route path="/Campus" element={<Campus />} />
                <Route path="/Course" element={<Course />} />
                <Route path="/Customer" element={<Customer />} />
                <Route path="/Finance" element={<Finance />} />
                <Route path="/Resource" element={<Resource />} />
                <Route path="/Revenue" element={<Revenue />} />
                <Route path="/Scholar" element={<Scholar />} />
                <Route path="/Setting" element={<Setting />} />
                <Route path="/Simulator" element={<Simulator />} />
                <Route path="/Summary" element={<Summary />} />
                <Route path="/Token" element={<Token />} />
                <Route path="/Version" element={<Version />} />
                <Route path="/Worker" element={<Worker />} />
            </Routes>
        </Suspense>
    );
}

export * from './Api';
export * from './Campus';
export * from './Course';
export * from './Customer';
export * from './Finance';
export * from './Resource';
export * from './Revenue';
export * from './Scholar';
export * from './Setting';
export * from './Simulator';
export * from './Summary';
export * from './Token';
export * from './Version';
export * from './Worker';
