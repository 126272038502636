const School = {
    content: '驾校管理',
    key: 'School',
};

const Student = {
    content: '学员管理',
    key: 'Student',
};

const Booking = {
    content: '预约学习',
    key: 'Booking',
};

const Status = {
    content: '模拟器状态',
    key: 'Status',
};

const Coach = {
    content: '教练资料',
    key: 'Coach',
};

const Assessment = {
    content: '教考设定',
    key: 'Assessment',
};

const Statement = {
    content: '对账资料',
    key: 'Statement',
};

const Clearance = {
    content: '通关设定',
    key: 'Clearance',
};

const Statistic = {
    content: '统计数据',
    key: 'Statistic',
};

const Customer = {
    content: '客户管理',
    key: 'Setting.Customer',
    admin: true,
};

const Campus = {
    content: '驾校管理',
    key: 'Setting.Campus',
    admin: true,
};

const Simulator = {
    content: '模拟器管理',
    key: 'Setting.Simulator',
    admin: true,
};

const Resource = {
    content: '组件更新',
    key: 'Setting.Resource',
    admin: true,
};

const Api = {
    content: 'Api说明',
    key: 'Setting.Api',
    admin: true,
    url: 'https://studycar.apifox.cn',
};

const Revenue = {
    content: '收支管理',
    key: 'Setting.Revenue',
    admin: true,
};

const Course = {
    content: '科目项目',
    key: 'Setting.Course',
    admin: true,
};

const Version = {
    content: 'APP版本',
    key: 'Setting.Version',
    admin: true,
};

const Token = {
    content: '存取令牌',
    key: 'Setting.Token',
    admin: true,
};

const Scholar = {
    content: '学员清单',
    key: 'Setting.Scholar',
    admin: true,
};

const Worker = {
    content: '使用者管理',
    key: 'Setting.Worker',
    admin: true,
};

const Setting = {
    content: '系统设定',
    key: 'Setting.Setting',
    admin: true,
};

const Finance = {
    content: '财务对账',
    key: 'Setting.Finance',
    admin: true,
};

const Summary = {
    content: '统计数据',
    key: 'Setting.Summary',
    admin: true,
};

const features = [
    { ...Booking },
    { ...Student },
    { ...School },
    { ...Coach },
    { ...Assessment },
    // { ...Statistic },
    { ...Statement },
    { ...Clearance },
    { ...Status },
    { ...Customer },
    { ...Campus },
    { ...Simulator },
    { ...Scholar },
    { ...Course },
    { ...Finance },
    { ...Revenue },
    { ...Summary },
    { ...Resource },
    { ...Token },
    { ...Worker },
    { ...Setting },
    { ...Api },
];

export default features;
