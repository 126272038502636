import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Worker;

const reducer = 'SettingWorkerReducer';

export default class SettingWorkerActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SETTING_WORKER_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.SETTING_WORKER_LOADING_SHOW,
        toggle,
    });

    static loading_resource = toggle => ({
        type: ACTION_TYPES.SETTING_WORKER_LOADING_RESOURCE,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.SETTING_WORKER_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (workers = []) => ({
        type: ACTION_TYPES.SETTING_WORKER_INDEX,
        workers,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('worker');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['worker', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static show = (worker = {}) => ({
        type: ACTION_TYPES.SETTING_WORKER_SHOW,
        worker,
    });

    static reset = () => ({
        type: ACTION_TYPES.SETTING_WORKER_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.SETTING_WORKER_COLUMN,
        columns,
    });

    static options = () => async (dispatch, getState) => {
        try {
            const res = await Api.options();
            if (res instanceof Error) throw res;
            dispatch({
                type: ACTION_TYPES.SETTING_WORKER_OPTION,
                options: res,
            });
        } catch (error) {
            console.error(error);
        } finally {
        }
    };
}
