import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is, List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../Common';

import Actions from './actions';

import PrintPreviewAction from '../Common/PrintPreview/actions';

const Detail = lazy(() => import('./Detail'));
const StudentDetail = lazy(() => import('../Setting/Scholar/Detail'));

export default function School(props) {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);

    const loading_index = useSelector(
        state => state.SchoolReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SchoolReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const schools = useSelector(
        state => state.SchoolReducer.get('schools'),
        (a, b) => is(a, b)
    );

    const school = useSelector(
        state => state.SchoolReducer.get('school'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SchoolReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SchoolReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const student = useSelector(
        state => state.StudentReducer.get('student'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Customer'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const onlines = useSelector(
        state => state.MainReducer.get('onlines'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                return dispatch(PrintPreviewAction.show({ section: 'schools', selected }));
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value } = values;

        if (name) {
            dispatch(Actions.show(school.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(school.merge(fromJS(values))));
        }
    };

    const handleRowSelect = rows => {
        setSelected(rows);
    };

    const DetailRef = useRef();
    const StudentDetailRef = useRef();

    const _school = school.update('simulators', simulators =>
        (simulators ?? List()).map(simulator => simulator.set('online', onlines.includes(simulator.get('id'))))
    );

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    rowSelect
                    selected={selected}
                    loading={loading_index}
                    columns={columns}
                    rows={schools}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                    onRowSelect={handleRowSelect}
                    customInput={
                        <Button
                            color="black"
                            icon="print"
                            type="button"
                            size="tiny"
                            disabled={!selected.length}
                            content="打印驾校二维码"
                            action="print"
                            onClick={handleAction}
                        />
                    }
                />
                <Suspense>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        school={_school}
                        onAction={handleAction}
                        onChange={handleChange}
                        StudentDetailRef={StudentDetailRef}
                    />

                    <StudentDetail user student={student} ref={StudentDetailRef} />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SchoolReducer } from './reducers';
export { default as SchoolAction } from './actions';
