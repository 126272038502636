import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Select, Sidebar } from 'semantic-ui-react';

import { DataGrid, Excel } from '../../Common';

import Actions from './actions';

import SettingCustomerAction from '../Customer/actions';
import PrintPreviewAction from '../../Common/PrintPreview/actions';

const Detail = lazy(() => import('./Detail'));

export default function SettingCampus(props) {
    const dispatch = useDispatch();

    const iniState = fromJS({
        schools_options: [],
        selected: [],
    });

    const [state, setState] = useState(iniState);

    const schools_options = state.get('schools_options').toJS();

    const selected = state.get('selected').toJS();

    const loading_index = useSelector(
        state => state.SettingCampusReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingCampusReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const campuses = useSelector(
        state => state.SettingCampusReducer.get('campuses'),
        (a, b) => is(a, b)
    );

    const campus = useSelector(
        state => state.SettingCampusReducer.get('campus'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingCampusReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingCampusReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Simulator'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const onlines = useSelector(
        state => state.MainReducer.get('onlines'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(SettingCustomerAction.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
        // dispatch(SchoolAction.options());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'export':
                return handleExport();
            case 'print':
                return dispatch(PrintPreviewAction.show({ section: 'schools', selected }));
            default:
        }
    };

    const handleExport = () => {
        const eventHandler = res => {
            Excel.emitter.removeListener('opened', eventHandler);
            dispatch(Actions.loading_index());
        };

        Excel.emitter.addListener('opened', eventHandler);

        Excel.create({ title: ['模拟器'], headers: columns, bodies: campuses });

        dispatch(Actions.loading_index());
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleChange = (evt, values) => {
        const { name, value, checked, options } = values;

        if (name === 'project2' || name === 'project3') {
            dispatch(
                Actions.show(campus.update('project', project => (project ?? 0) + (checked ? value : value * -1)))
            );
        } else if (name) {
            dispatch(Actions.show(campus.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(campus.merge(fromJS(values))));
        }

        if (name === 'customers_id') {
            const schools = options.find(o => o.value === value)?.schools ?? [];
            setState(state.set('schools_options', fromJS(schools)));
        }
    };

    const handleRowSelect = selected => {
        setState(state.set('selected', fromJS([...selected])));
    };

    const DetailRef = useRef();

    const customer = search.get('customer') ?? '';

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    rowSelect
                    selected={selected}
                    loading={loading_index}
                    columns={columns}
                    rows={campuses}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    customInput={
                        <Button
                            color="black"
                            icon="print"
                            type="button"
                            size="tiny"
                            disabled={!selected.length}
                            content="打印驾校二维码"
                            action="print"
                            onClick={handleAction}
                        />
                    }
                    rightInput={
                        <React.Fragment>
                            <Select
                                clearable
                                search
                                placeholder="客户"
                                options={customers_options}
                                name="customer"
                                value={customer}
                                onChange={handleSearch}
                            />
                        </React.Fragment>
                    }
                    onRowSelect={handleRowSelect}
                />
                <Suspense>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        campus={campus}
                        customers_options={customers_options}
                        schools_options={schools_options}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingCampusReducer } from './reducers';
export { default as SettingCampusAction } from './actions';
