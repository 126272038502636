import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Sidebar } from 'semantic-ui-react';

import { DataGrid, Excel } from '../../Common';

import Actions from './actions';

import SettingCustomerAction from '../Customer/actions';

const Detail = lazy(() => import('./Detail'));

export default function SettingSimulator(props) {
    const dispatch = useDispatch();

    const iniState = fromJS({
        schools_options: [],
        selected: [],
    });

    const [state, setState] = useState(iniState);

    const schools_options = state.get('schools_options').toJS();

    const selected = state.get('selected').toJS();

    const loading_index = useSelector(
        state => state.SettingSimulatorReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingSimulatorReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const simulators = useSelector(
        state => state.SettingSimulatorReducer.get('simulators'),
        (a, b) => is(a, b)
    );

    const simulator = useSelector(
        state => state.SettingSimulatorReducer.get('simulator'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingSimulatorReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingSimulatorReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Simulator'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const onlines = useSelector(
        state => state.MainReducer.get('onlines'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(SettingCustomerAction.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
        // dispatch(SchoolAction.options());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'export':
                return handleExport();
            default:
        }
    };

    const handleExport = () => {
        const eventHandler = res => {
            Excel.emitter.removeListener('opened', eventHandler);
            dispatch(Actions.loading_index());
        };

        Excel.emitter.addListener('opened', eventHandler);

        Excel.create({ title: ['模拟器'], headers: columns, bodies: simulators });

        dispatch(Actions.loading_index());
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.simulators_id));
        return DetailRef?.current?.handleOpen();
    };

    const handleChange = (evt, values) => {
        const { name, value, options } = values;

        if (name) {
            dispatch(Actions.show(simulator.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(simulator.merge(fromJS(values))));
        }

        if (name === 'customers_id') {
            const schools = options.find(o => o.value === value)?.schools ?? [];
            setState(state.set('schools_options', fromJS(schools)));
        }
    };

    const handleRowSelect = selected => {
        setState(state.set('selected', fromJS([...selected])));
    };

    const DetailRef = useRef();

    const customer = search.get('customer') ?? '';

    const _simulators = simulators.map(simulator => {
        return simulator.set('online', onlines.includes(simulator.get('serial_no') ?? ''));
    });

    const status = [
        { label: '离线', value: _simulators.filter(s => !s.get('online')).size },
        { label: '在线', value: _simulators.filter(s => s.get('online')).size },
    ];

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    status={status}
                    remit
                    rowSelect
                    loading={loading_index}
                    columns={columns}
                    rows={_simulators}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    rightInput={
                        <React.Fragment>
                            <Select
                                clearable
                                search
                                placeholder="客户"
                                options={customers_options}
                                name="customer"
                                value={customer}
                                onChange={handleSearch}
                            />
                        </React.Fragment>
                    }
                    onRowSelect={handleRowSelect}
                />
                <Suspense>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        simulator={simulator}
                        customers_options={customers_options}
                        schools_options={schools_options}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingSimulatorReducer } from './reducers';
export { default as SettingSimulatorAction } from './actions';
